import React from "react";
import { Link } from "react-router-dom";

export default function Error404Page() {
  return (
    <div id="page-container" className="side-trans-enabled">
      <main id="main-container">
        <div className="bg-image">
          <div className="hero bg-white-95">
            <div className="hero-inner">
              <div className="content content-full">
                <div className="px-3 py-5 text-center">
                  <div
                    className="row js-appear-enabled animated fadeIn"
                    data-toggle="appear"
                  >
                    <div className="col-sm-6 text-center text-sm-right">
                      <div className="display-1 text-danger font-w700">404</div>
                    </div>
                    <div className="col-sm-6 text-center d-sm-flex align-items-sm-center">
                      <div className="display-1 text-muted font-w300">
                        Error
                      </div>
                    </div>
                  </div>
                  <h1
                    className="h2 font-w700 mt-5 mb-3 js-appear-enabled animated fadeInUp"
                    data-toggle="appear"
                    data-classname="animated fadeInUp"
                    data-timeout="300"
                  >
                    Oops.. Parece que encontramos um erro..
                  </h1>
                  <h2
                    className="h3 font-w400 text-muted mb-5 js-appear-enabled animated fadeInUp"
                    data-toggle="appear"
                    data-classname="animated fadeInUp"
                    data-timeout="450"
                  >
                    Pedimos desculpa, mas a página que está tentando acessar não
                    existe ou não está mais disponível..
                  </h2>
                  <div
                    className="js-appear-enabled animated fadeInUp"
                    data-toggle="appear"
                    data-classname="animated fadeInUp"
                    data-timeout="600"
                  >
                    <Link to="/" className="btn btn-hero-secondary">
                      <i className="fa fa-arrow-left mr-1" /> Voltar ao sistema
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
